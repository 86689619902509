// Core variables and mixins
@charset "UTF-8";

@import "bourbon";
@import "variables";
@import "mixins";

$primary-color: #E47C49;

body {
    > .pusher {
        background-color: #F2F2F0 !important;
    }
}

header {
    background-color: #264182;

    .logo {
        a {
            background-image: url(../images/el-gym-en-casa/logo.png);
            width: 305px;
            height: 85px;

            margin-bottom: 1em !important;

            @include media-sm {
                margin-bottom: 0 !important;
            }
        }
    }

    margin-bottom: 1rem;
}

#menu {
    margin-bottom: 0 !important;
}

.item.sidebar-menu {
    display: none !important;
}

.ui.primary.buttons .button, .ui.primary.button, .ui.blue.button {
    background-color: $primary-color;

    &:hover {
        background-color: darken($primary-color, 20);
    }

    &:active {
        background-color: darken($primary-color, 20);
    }
}

.ui.stackable.grid {
    > .row {
        > .column {
            padding-bottom: 0 !important;
            @include media-sm {
                padding-bottom: 1rem !important;
            }
        }
    }
}

.ui.card:not(.horizontal) {
    flex-direction: row;
    @include media-sm {
        flex-direction: column;
    }

    > * {
        flex: 1 0 50% !important;;
    }

    .image {
        border-radius: 0.28571429rem 0em 0em 0.28571429rem !important;
        max-width: 150px;
        border-right: 1px solid rgba(34, 36, 38, 0.0980392);

        @include media-sm {
            max-width: 100%;
            border-radius: 0.28571429rem 0.28571429rem 0em 0em !important;
        }
    }

    .content {
        border-top-width: 0;

        @include media-sm {
            border-top-width: 1px;
            border-right-width: 0;
        }
    }

}

#footer {
    background-color: #264182 !important;
}
